<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>

    <div class="flex align-items-center justify-content-between">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
      </div>
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center">
        <div *ngIf="isSuperAdmin">
          <p-dropdown [options]="tenantList" [(ngModel)]="tenantUUIDSelected" (onChange)="changeTenant($event)"
                      [placeholder]="tenantUUIDSelected ? tenantSelected?.name : 'Seleccionar Marca'"
                      optionLabel="name" optionValue="uuid"
                      class="dropdown tenant-dropdown"></p-dropdown>
        </div>
      </div>
    </div>

    <div class="table__container">
      <p-table #dt [value]="categoriesList" [paginator]="true" selectionMode="single" [rows]="10" [sortOrder]="1"
               sortField="position" (onRowReorder)="onRowReorder($event)" [reorderableColumns]="true"
               [globalFilterFields]="fieldsFilter" [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Mostrar"
               [showCurrentPageReport]="true" class="table" [loading]="isLoading" stateStorage="session"
               stateKey="categories-table">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <div class="flex justify-content-start align-items-center">
              <i class="fa-light fa-list-ol text-3xl"></i>
              <p class="my-0 text-xl ml-3 font-semibold">CATEGORÍAS EN CARTELERA</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ categoriesList?.length | number:'1.0-2' }}</span>
              </div>
            </div>
            <div class="flex align-items-center">
              <button pButton label="Nueva categoría" icon="fa-regular fa-circle-plus fa-lg"
                      class="btn btn__primary btn__size--xs mr-3" (click)="openNew()"></button>

              <div class="search-container">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                         placeholder="Buscar categoría" [(ngModel)]="searchTerm"/>
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!-- <th pSortableColumn="trxId">ID <p-sortIcon field="id"></p-sortIcon></th> -->
            <th style="width:3rem"></th>
            <th pSortableColumn="position" pReorderableColumn>
              Posición
              <p-sortIcon field="position"></p-sortIcon>
            </th>
            <th pSortableColumn="name" pReorderableColumn>
              Categoría
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="quantityEvents" pReorderableColumn>
              Eventos
              <p-sortIcon field="quantityEvents"></p-sortIcon>
            </th>
            <th pReorderableColumn></th>
            <th pReorderableColumn></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-category let-index="rowIndex">
          <tr class="table-row" [pReorderableRow]="index">
            <td>
              <i class="pi pi-bars" pReorderableRowHandle></i>
            </td>
            <td class="text-sm font-normal">
              {{ category.position }}
            </td>
            <td class="text-sm font-normal">
              {{ category.name }}
            </td>
            <td class="text-sm font-normal">
              {{ category.quantityEvents }}
            </td>
            <td>
              <div class="show-in-billboard">
                <span>Mostrar en cartelera </span>
                <p-inputSwitch [(ngModel)]="category.showOnBillboard"
                               (onChange)="checkShowOnBillboard($event, category.idCategory)"></p-inputSwitch>
              </div>
            </td>
            <td style="width: 10%; text-align: center">
              <i class="fa-solid fa-pen-to-square font-size-18 text-primary mr-4"
                 (click)="editCategoryEvent(category)"></i>
              <i class="fal fa-trash-alt font-size-18 text-primary " (click)="deleteCategoryEvent(category)"></i>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>

    <p-confirmDialog header="" acceptLabel="Confirmar" rejectLabel="Cancelar" rejectIcon="" acceptIcon=""
                     rejectButtonStyleClass="btn btn__secondary btn__secondary--bold" key="confirmDelete"
                     acceptButtonStyleClass="btn btn__primary">
    </p-confirmDialog>

  </div>
</div>
