<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>
    <div class="flex align-items-center justify-content-between">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
      </div>
      <div *ngIf="isSuperAdmin">
        <p-dropdown [options]="tenantList" [(ngModel)]="tenantUUIDSelected" (onChange)="changeTenant($event)"
                    [placeholder]="tenantUUIDSelected ? tenantSelected?.name : 'Seleccionar Marca'"
                    optionLabel="name" optionValue="uuid"
                    class="dropdown tenant-dropdown"></p-dropdown>
      </div>
    </div>
    <div class="table__container">
      <p-table #dt [value]="events" [lazy]="true" (onLazyLoad)="loadEvents($event, tenantUUIDSelected)" [rows]="pageSize"
               [paginator]="true" stateStorage="session" stateKey="events-table"
               dataKey="idEvent" expandableRows="true"
               selectionMode="single" [loading]="loading" sortMode="multiple" [rowsPerPageOptions]="[10,25,50,100]"
               currentPageReportTemplate="Mostrar"
               [showCurrentPageReport]="true" class="table table-events" rowExpandMode="single" [rowHover]="true"
               [totalRecords]="totalRecords">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <div class="flex justify-content-start align-items-center">
              <i class="fa-light fa-calendar-star fa-lg"></i>
              <p class="my-0 text-xl ml-3 font-semibold">EVENTOS</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ totalRecords | number:'1.0-2' }}</span>
              </div>
            </div>
            <div class="search-container">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="Buscar por nombre" [(ngModel)]="searchTerm"/>
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="idEvent">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                ID
                <p-sortIcon field="idEvent"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="eventName">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Nombre
                <p-sortIcon field="eventName"></p-sortIcon>
              </div>
            </th>
            <th>Comercio</th>
            <th pSortableColumn="dateInit">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Inicio
                <p-sortIcon field="dateInit"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="dateEnd">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Fin
                <p-sortIcon field="dateEnd"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="verified">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Estado
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr class="table-row" (click)="goToEventDetail(event)">
            <td [pRowToggler]="event" class="text-sm font-normal">
              {{ event.idEvent }}
            </td>
            <td [pRowToggler]="event" class="text-sm font-normal">
              {{ event.eventName }}
            </td>
            <td [pRowToggler]="event">
              <div *ngIf="event.storeResponse.name">{{ event.storeResponse.name }}</div>
            </td>
            <td [pRowToggler]="event" class="text-sm font-normal">
              {{ event.dateInit | date: "dd MMM " | uppercase }}'
              {{ event.dateInit | date: "yy - HH:mm" }}
            </td>
            <td [pRowToggler]="event" class="text-sm font-normal">
              {{ event.dateEnd | date: "dd MMM " | uppercase }}'
              {{ event.dateEnd | date: "yy - HH:mm" }}
            </td>
            <td [pRowToggler]="event">
              <div *ngIf="!event.verified">
                <span class="bid-badge bid-badge--orange">SIN VERIFICAR</span>
              </div>

              <div *ngIf="event.verified">
                <span [ngSwitch]="event.status">
                  <span *ngSwitchCase="EventStatus.LIQUIDATED" class="bid-badge bid-badge--green">
                    LIQUIDADO
                  </span>
                  <span *ngSwitchCase="EventStatus.PENDING" class="bid-badge bid-badge--white">
                    SIN INICIAR
                  </span>
                  <span *ngSwitchCase="EventStatus.IN_PROGRESS" class="bid-badge bid-badge--green">
                    EN CURSO
                  </span>
                  <span *ngSwitchCase="EventStatus.FINALIZED" class="bid-badge bid-badge--grey">
                    FINALIZADO
                  </span>
                  <span *ngSwitchCase="EventStatus.CANCELED" class="bid-badge bid-badge--red">
                    CANCELADO
                  </span>
                </span>
              </div>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog [(visible)]="showModalImage" (onHide)="onHide()" [draggable]="false" appendTo="body"
          [style]="{ width: '550px' }" header="{{ headerPopup }}" [modal]="true" styleClass="mt-2 dialog-image">
  <ng-template pTemplate="content">
    <div>
      <img [src]="getUrlImage(imageEventShow)" alt="Event"/>
    </div>
  </ng-template>
</p-dialog>
