import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CategoryEventRequest} from '../core/models/CategoryEventRequest';
import {CategoryEventResponse} from '../core/models/CategoryEventResponse ';
import {ReOrderCategoriesRequest} from '../core/models/ReOrderCategoriesRequest';
import {ShowOnBillboardCategoryRequest} from '../core/models/ShowOnBillboardCategoryRequest';

@Injectable({
  providedIn: 'root',
})
export class CategoriesEventsService {
  constructor(private httpC: HttpClient) {
  }

  getCategoriesEventByTenant(tenantUUID): Observable<CategoryEventResponse[]> {
    return this.httpC.get<CategoryEventResponse[]>(
      environment.billboardAGUrl + `/cartelera/categories-events/categories/${tenantUUID}`
    );
  }

  saveCategoryEvent(
    categoryEventRequest: CategoryEventRequest
  ): Observable<number> {
    return this.httpC.post<number>(
      environment.billboardAGUrl + `/cartelera/categories-events/categories`,
      categoryEventRequest
    );
  }

  updateCategoryEvent(
    idCategory: number,
    categoryEventRequest: CategoryEventRequest
  ): Observable<number> {
    return this.httpC.patch<number>(
      environment.billboardAGUrl +
      `/cartelera/categories-events/categories/${idCategory}`,
      categoryEventRequest
    );
  }

  showOnBillboard(
    idCategory: number,
    showOnBillboardCategoryRequest: ShowOnBillboardCategoryRequest
  ): Observable<void> {
    return this.httpC.patch<void>(
      environment.billboardAGUrl +
      `/cartelera/categories-events/categories/show-on-billboard/${idCategory}`,
      showOnBillboardCategoryRequest
    );
  }

  reOrderCategoryEvent(
    reorderCategoriesEventRequest: ReOrderCategoriesRequest
  ): Observable<number> {
    return this.httpC.post<number>(
      environment.billboardAGUrl + `/cartelera/categories-events/categories/reorder`,
      reorderCategoriesEventRequest
    );
  }

  deleteCategoryEvent(categoryId: number): Observable<void> {
    return this.httpC.delete<void>(
      environment.billboardAGUrl +
      `/cartelera/categories-events/categories/${categoryId}`
    );
  }
}
