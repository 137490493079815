<div class="container">
  <div class="wrapper">
    <div class="btn__admin" (click)="goToStores()">
      <i class="fas fa-store"></i>
      <p class="btn__admin--title">Comercios</p>
    </div>
    <div class="btn__admin" (click)="goToEvents()">
      <i class="fas fa-calendar-star"></i>
      <p class="btn__admin--title">Eventos</p>
    </div>
    <div *ngIf="isSuperAdmin" class="btn__admin" (click)="goToCustomers()">
      <i class="fas fa-user-group"></i>
      <p class="btn__admin--title">Usuarios</p>
    </div>
    <div class="btn__admin" (click)="goToTransactions()">
      <i class="fas fa-magnifying-glass-dollar"></i>
      <span class="btn__admin--title">Transacciones</span>
    </div>
    <!--div class="btn__admin" (click)="goToReservations()">
      <i class="fas fa-calendar-day"></i>
      <p class="btn__admin--title">Reservas</p>
    </div-->
    <div class="btn__admin" (click)="goToCategories()">
      <i class="fas fa-list-ol"></i>
      <p class="btn__admin--title categories">Categorías en <br/>Cartelera</p>
    </div>
    <div *ngIf="isSuperAdmin" class="btn__admin" (click)="goToAdmins()">
      <i class="fas fa-users-gear"></i>
      <p class="btn__admin--title">Administradores</p>
    </div>
    <div class="btn__admin" (click)="goToSales()">
      <i class="fas fa-chart-column"></i>
      <p class="btn__admin--title">Estadísticas & Ventas</p>
    </div>

  </div>
</div>
