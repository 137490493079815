import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';

import {CategoriesEvent} from 'src/app/core/models/CategoriesEvent';
import {CategoriesEventsService} from 'src/app/services/categories-events.service';
import {CategoryEvent} from 'src/app/core/models/CategoryEvent';
import {CategoryEventResponse} from 'src/app/core/models/CategoryEventResponse ';
import {CategoryFormModalComponent} from './components/category-form-modal/category-form-modal.component';
import {DialogService} from 'primeng/dynamicdialog';
import {UntypedFormGroup} from '@angular/forms';
import {ReOrderCategoriesRequest} from 'src/app/core/models/ReOrderCategoriesRequest';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {Router} from '@angular/router';
import {ShowOnBillboardCategoryRequest} from 'src/app/core/models/ShowOnBillboardCategoryRequest';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Tenant} from "../../../core/models/Tenant";
import {StoreService} from "../../../services/store.service";
import {CategoryEventRequest} from "../../../core/models/CategoryEventRequest";
import {Table} from "primeng/table";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-categories-events',
  templateUrl: './categories-events.component.html',
  styleUrls: ['./categories-events.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class CategoriesEventsComponent implements OnInit {
  @ViewChild('dt') table: Table;
  categoryFormModalComp: CategoryFormModalComponent;
  categoriesList: Array<CategoryEventResponse>;
  categoriesEventForm: UntypedFormGroup;
  isLoading = true;
  showCategoryForm = false;
  isEditing: boolean = false;
  categoryEditData: CategoryEvent = null;
  subs = [];
  fieldsFilter: Array<string> = ['name'];
  categoryEditing: CategoriesEvent = null;
  lastPosition: number;
  refresh: boolean;
  tenantUUIDSelected: string;
  tenantSelected: Tenant = null;
  tenantList: Tenant[] = [];
  searchTerm = null;
  isSuperAdmin: boolean;

  constructor(
    private categoriesEventSrv: CategoriesEventsService,
    private router: Router,
    private confirmationSrv: ConfirmationService,
    private spinnerSrv: SpinnerService,
    private dialogSrv: DialogService,
    private errorSrv: RequestErrorTriggerService,
    private storeSrv: StoreService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    if (this.isSuperAdmin) {     
      this.getTenantList();
    }
    this.tenantUUIDSelected = localStorage.getItem('tenant');
    this.searchTerm = sessionStorage.getItem('categories-table') ? JSON.parse(sessionStorage.getItem('categories-table')).filters.global.value : null;
    this.getCategoriesEvent();
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
        if (sessionStorage.getItem('tenant-categories')) {
          this.tenantUUIDSelected = sessionStorage.getItem('tenant-categories');
        } else {
          this.tenantUUIDSelected = this.tenantList[0].uuid;
        }
        this.tenantSelected = this.tenantList[0];
        this.getCategoriesEvent();
      }
    });
  }

  getCategoriesEvent(): void {
    if (this.tenantUUIDSelected !== "undefined") {
      this.categoriesEventSrv.getCategoriesEventByTenant(this.tenantUUIDSelected).subscribe({
        next: (res: Array<CategoryEventResponse>) => {
          this.categoriesList = res;
          this.isLoading = false;
          this.spinnerSrv.loadSpinner.next(false);
          if (this.categoriesList.length === 0) {
            this.lastPosition = null;
          } else {
            this.lastPosition = res[res.length - 1].position;
          }
        },
        error: (err) => {
          this.handleErrorMessage(err.status);
          this.spinnerSrv.loadSpinner.next(false);
        }
      });
    }
  }

  changeTenant(event) {
    this.tenantUUIDSelected = event.value;
    this.tenantSelected = this.tenantList.find(te => te.uuid === this.tenantUUIDSelected);
    sessionStorage.removeItem('categories-table');
    this.table.reset();
    this.searchTerm = null;
    sessionStorage.setItem('tenant-categories', event.value);
    this.getCategoriesEvent();
  }

  onRowReorder(e): void {
    const payloadReorder: ReOrderCategoriesRequest = {
      oldPosition: e.dragIndex + 1,
      newPosition: e.dropIndex + 1,
      tenant: this.tenantUUIDSelected
    };
    this.spinnerSrv.loadSpinner.next(true);
    this.categoriesEventSrv.reOrderCategoryEvent(payloadReorder).subscribe({
      next: () => {
        this.onRefresh();
      },
      error: (err) => {
        this.handleErrorMessage(err.status);
      }
    });
  }

  checkShowOnBillboard(e, categoryEventId: number): void {
    this.spinnerSrv.loadSpinner.next(true);
    const payload: ShowOnBillboardCategoryRequest = {
      showOnBillboard: e.checked,
    };
    this.categoriesEventSrv.showOnBillboard(categoryEventId, payload).subscribe({
      next: () => {
        this.onRefresh();
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        this.handleErrorMessage(err.status);
      }
    });
  }

  openNew(): void {
    this.isEditing = false;
    this.showCategoryFormModal(this.isEditing);
  }

  editCategoryEvent(category: CategoriesEvent): void {
    this.isEditing = true;
    this.categoryEditing = category;
    this.showCategoryForm = true;
    category.lastPosition = this.lastPosition;

    this.showCategoryFormModal(this.isEditing);
  }

  showCategoryFormModal(isEditing: boolean): void {
    const ref = this.dialogSrv.open(CategoryFormModalComponent, {
      header: this.isEditing ? this.categoryEditing.name : 'Nueva categoría',
      width: '526px',
      styleClass: 'category-form-container',
      data: {
        isEditing,
        categoryEditData: this.categoryEditing ? this.categoryEditing : null,
        lastPosition: this.lastPosition ? this.lastPosition : 0,
      },
    });

    ref.onClose.subscribe({
      next: (res) => {
        if (res) {
          this.submitCategory(res);
        }
      }
    });
  }

  deleteCategoryEvent(category: CategoryEvent): void {
    this.confirmationSrv.confirm({
      message: '¿Deseas eliminar esta categoría?',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      acceptLabel: 'Eliminar',
      rejectLabel: 'No',
      key: 'confirmDelete',
      accept: () => {
        this.spinnerSrv.loadSpinner.next(true);
        this.subs.push(
          this.categoriesEventSrv.deleteCategoryEvent(category.idCategory).subscribe({
            next: () => {
              this.onRefresh();
              this.spinnerSrv.loadSpinner.next(false);
            },
            error: (err) => {
              this.handleErrorMessage(err.status);
              this.spinnerSrv.loadSpinner.next(false);
            }
          })
        );
      },
    });
  }

  back(): void {
    this.router.navigate(['home']);
  }

  onRefresh(): void {
    this.isLoading = true;
    this.getCategoriesEvent();
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Evento no encontrado';
        break;
      case 409:
        message = 'No es posible eliminar una categoría que este asignada a un evento.';
        break;
    }

    this.errorSrv.updateShowError({
      showError: true,
      message,
    });
  }

  submitCategory(categoryEventRequest: CategoryEventRequest): void {
    if (categoryEventRequest === null) return;
    this.spinnerSrv.loadSpinner.next(true);
    categoryEventRequest.tenant = this.tenantUUIDSelected;
    if (this.isEditing) {
      this.categoriesEventSrv.updateCategoryEvent(this.categoryEditing.idCategory, categoryEventRequest).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.categoryEditing = null;
          this.onRefresh();
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          this.handleErrorMessage(err.status);
        }
      });
    } else {
      this.categoriesEventSrv.saveCategoryEvent(categoryEventRequest).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.categoryEditing = null;
          this.onRefresh();
        },
        error: (err) => {
          this.handleErrorMessage(err.status);
          this.spinnerSrv.loadSpinner.next(false);
        }
      });
    }
  }
}
